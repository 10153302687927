import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { LOGIN_SUCCESS, LOGIN_ATTEMPT } from '../ducks/auth'
import qs from 'qs'

const mapStateToProps = state => {
  return {
    loggedIn: !!state.auth.currentUser
  }
}

const mapDispatchToProps = dispatch => {
  return {
    finaliseLogin: ({ id, token }) => dispatch(LOGIN_SUCCESS({ id, token })),
    login: () => dispatch(LOGIN_ATTEMPT())
  }
}

class Authorize extends React.Component {
  constructor(props) {
    super(props)
    const query = qs.parse(window.location.hash)
    const token = query['#access_token']
    if (token) {
      props.finaliseLogin({
        id: query.session_state,
        scopes: query.scope,
        token
      })
    } else if (query['#error'] === 'interaction_required') {
      this.props.login({ prompt: 'consent' })
    } else {
      window.location.href = '/login'
    }
  }
  render() {
    const query = qs.parse(window.location.hash)
    const previousURL = query['state'] && decodeURIComponent(query['state'])
    return this.props.loggedIn ? <Redirect to={previousURL || '/'} /> : ''
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Authorize)
