import { connect } from 'react-redux'
import { CHANGE_ENVIRONMENT } from '../ducks/environments'
import { INVALIDATE_USERS, REQUEST_USERS } from '../ducks/users'
import { INVALIDATE_ROLES, REQUEST_ROLES } from '../ducks/roles'
import EnvPicker from '../components/EnvPicker'

const mapStateToProps = state => {
  return {
    environments: Object.values(state.environments.byId),
    currentEnv: state.environments.current
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeEnvironment: envId => {
      dispatch(INVALIDATE_USERS())
      dispatch(INVALIDATE_ROLES())
      dispatch(CHANGE_ENVIRONMENT(envId))
      dispatch(REQUEST_ROLES({}))
      dispatch(REQUEST_USERS({}))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnvPicker)
