import { connect } from 'react-redux'
import { REQUEST_USERS, DELETE_USER, SHOW_FORM, REQUEST_RESEND_INVITE } from '../ducks/users'
import UserList from '../components/UserList'

const mapStateToProps = state => {
  return {
    users: Object.values(state.users.byId),
    roleFilter: state.users.roleFilter,
    loading: state.users.loading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchUsers: role => dispatch(REQUEST_USERS({ role })),
    deleteUser: id => dispatch(DELETE_USER(id)),
    showForm: id => dispatch(SHOW_FORM(id)),
    resendInvite: user => dispatch(REQUEST_RESEND_INVITE(user))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList)
