import { setup } from 'axios-cache-adapter'
import authURL from './auth'

const baseURLs = {
  production: 'https://gmdppowerplatform-umapi.greatermanchester.org.uk',
  // production: 'https://gmdp-ppum-uks-prod-fa.azurewebsites.net',
  // development: 'https://ans-ppum-uks-prod-fa.azurewebsites.net'
  development: '//localhost:7071'
}

const baseURL = `${getValue(baseURLs)}/api`

const restAPI = setup({ baseURL, ignoreCache: process.env.NODE_ENV !== 'production' })

const updateToken = (token) => {
  restAPI.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export { restAPI, updateToken, authURL }

function getValue(from) {
  return from[process.env.NODE_ENV] || from.development
}
