import { connect } from 'react-redux'
import { REQUEST_JOBS } from '../ducks/jobs'
import ImportStatus from '../components/ImportStatus'

const mapStateToProps = state => {
  return {
    importing: state.jobs.polling,
    error: state.jobs.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchJobs: () => dispatch(REQUEST_JOBS())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportStatus)
