import React, { useEffect } from 'react'
import Table from '../Table'
import RowActions from './RowActions'
import UserListControls from '../../containers/UserListControls'
import styles from './UserList.module.scss'

const UserList = ({ users, roleFilter, loading, fetchUsers, showForm, deleteUser, resendInvite }) => {
  useEffect(() => {
    fetchUsers(roleFilter)
  }, [ roleFilter, fetchUsers ])

  return (
    <div>
      <UserListControls />
      <Table
        columns={getTableCols(showForm, deleteUser, resendInvite)}
        data={users}
        loading={loading}
      />
    </div>
  )
}

function getTableCols(showForm, deleteUser, resendInvite) {
  const tableCols = [
    {
      title: 'User Name',
      dataIndex: 'displayName',
      key: 'displayName',
      render: function UserNameCell(user) {
        return <span className={styles.openLink} onClick={() => showForm(user.id)}>{user.displayName}</span>
      }
    },
    {
      title: 'Email',
      dataIndex: 'mail',
      key: 'mail'
    },
    {
      title: '',
      key: 'actions',
      render: function ActionsCell(user) {
        return <RowActions user={user} deleteUser={deleteUser} showForm={showForm} resendInvite={resendInvite} />
      }
    }
  ]

  return tableCols
}

export default UserList
