import { connect } from 'react-redux'
import { CREATE_USERS } from '../ducks/users'
import { SHOW_MESSAGE } from '../ducks/messages'
import ImportUsers from '../components/ImportUsers'

const mapStateToProps = state => {
  return {
    loading: state.users.submitting || state.roles.loading,
    roles: Object.values(state.roles.byId)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    importUsers: items => dispatch(CREATE_USERS(items)),
    showMessage: message => dispatch(SHOW_MESSAGE(message))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportUsers)
