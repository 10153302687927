import React from 'react'
import moment from 'moment'
import 'moment/locale/en-gb'
import ReactDOM from 'react-dom'
import 'normalize.css'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { all } from 'redux-saga/effects'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import logger from 'redux-logger'

import App from './App'
import authReducer, { saga as authSaga } from './ducks/auth'
import usersReducer, { saga as usersSaga } from './ducks/users'
import rolesReducer, { saga as rolesSaga } from './ducks/roles'
import jobsReducer, { saga as jobsSaga } from './ducks/jobs'
import environmentsReducer, { saga as envSaga } from './ducks/environments'
import messageReducer from './ducks/messages'
import * as serviceWorker from './serviceWorker'

moment.locale('en-GB')

// Redux/Saga

const sagaMiddleware = createSagaMiddleware()

function * rootSaga() {
  yield all([
    authSaga(),
    usersSaga(),
    rolesSaga(),
    envSaga(),
    jobsSaga()
  ])
}

const middleware = [
  ...getDefaultMiddleware(),
  sagaMiddleware
]
if (process.env.NODE_ENV !== 'production') {
  middleware.push(logger)
}
const store = configureStore({
  reducer: {
    auth: authReducer,
    users: usersReducer,
    roles: rolesReducer,
    jobs: jobsReducer,
    environments: environmentsReducer,
    messages: messageReducer
  },
  middleware,
  devTools: {
    trace: true
  }
})

sagaMiddleware.run(rootSaga)

// End Redux/Saga

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'))

console.log('%cMade by ANS', 'font-weight: bold; font-size: 2.5rem; background: #2e104b; color: #fff; padding: 15px 20px;')
console.log('%c   https://ans.co.uk', 'font-size: 1.4rem;')

serviceWorker.unregister()
