import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { connect } from 'react-redux'
import { LOGOUT } from '../ducks/auth'
import Toasts from './Toasts'
import styles from '../styles/Layout.module.scss'
import Nav from '../components/Nav'
import ImportStatus from '../containers/ImportStatus'
import Drawer from '../components/Drawer'
import UserForm from './UserForm'

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser && !state.auth.validating,
    userFormVisible: state.users.formActive,
    envsLoaded: Object.keys(state.environments.byId).length > 0
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(LOGOUT())
  }
}

const Layout = ({ currentUser, logout, envsLoaded, userFormVisible, children }) => (
  <Router>
    <div>
      <Toasts position="bottomRight" />
      <div className={styles.root}>
        {currentUser && envsLoaded && <ImportStatus />}
        {currentUser && <Nav logout={logout} />}
        <div className={styles.content}>
          {children}
        </div>
        <footer className={styles.footer}>©{new Date().getFullYear()} GMCA. All Rights Reserved.</footer>
      </div>
      <Drawer visible={userFormVisible}>
        <UserForm />
      </Drawer>
    </div>
  </Router>
)

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
