import keyBy from 'lodash/keyBy'
import { createSlice } from '@reduxjs/toolkit'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import { SHOW_MESSAGE } from './messages'
import { restAPI } from '../api'

const { actions, reducer } = createSlice({
  initialState: {
    loading: false,
    lastUpdated: null,
    byId: {}
  },
  name: 'roles',
  reducers: {
    REQUEST_ROLES(state) {
      state.loading = true
    },
    INVALIDATE_ROLES(state) {
      state.invalidated = true
    },
    ROLES_REQUEST_SUCCESS(state, { payload }) {
      state.loading = false
      state.invalidated = false
      state.lastUpdated = new Date().toString()
      state.byId = keyBy(payload.roles, 'id')
    },
    ROLE_REQUEST_FAILED(state) {
      state.invalidated = false
      state.loading = false
    }
  }
})

function * onRequestRoles() {
  try {
    const environment = yield select(state => state.environments.current)
    const invalidated = yield select(state => state.users.invalidated)
    const { data } = yield call(
      restAPI.get, `/roles?environment=${environment}`, { cache: {
        ignoreCache: invalidated
      } }
    )
    yield put(actions.ROLES_REQUEST_SUCCESS(data))
  } catch ({ response }) {
    yield put(actions.ROLE_REQUEST_FAILED({ message: (response && response.data.message) || 'Something went wrong' }))
  }
}
function * onRequestRoleFail({ payload }) {
  yield put(SHOW_MESSAGE({ status: 'error', title: payload.title, text: payload.message }))
}

export function * saga() {
  yield takeLatest('roles/REQUEST_ROLES', onRequestRoles)
  yield takeLatest('roles/ROLE_REQUEST_FAILED', onRequestRoleFail)
}

export const { REQUEST_ROLES, INVALIDATE_ROLES } = actions

export default reducer
