import throttle from 'lodash/throttle'
import React, { useEffect, Fragment } from 'react'
import styles from './UserListControls.module.scss'
import Select from '../Select'
import Button from '../Button'
import Popup, { Item as PopupItem } from '../Popup'
import ImportUsers from '../../containers/ImportUsers'

const UserListControls = ({ roleFilter, showForm, roles, loading, fetchRoles, updateRoleFilter, requestExport }) => {
  useEffect(() => {
    fetchRoles()
  }, [ fetchRoles ])

  const handleChange = ({ target }) => {
    const role = target && target.value
    if (roleFilter !== role) {
      updateRoleFilter(role)
    }
  }

  const throttledRequestExport = throttle(requestExport, 30000)

  const options = [{ text: 'All Roles', value: '' }].concat(roles.map(role => ({ text: role.displayName, value: role.id })))

  return (
    <div className={styles.root}>
      <Select
        loading={loading}
        onChange={handleChange}
        options={options}
        defaultValue={options[0]}
      />
      <Button className={styles.createButton} onClick={showForm}>Create User</Button>
      <Popup
        className={styles.popupWrapper}
        content={
          <Fragment>
            <ImportUsers />
            <PopupItem onClick={throttledRequestExport}>Export Users</PopupItem>
          </Fragment>
        }
      >
        <Button icon="ChevronDown" className={styles.popupButton}/>
      </Popup>
    </div>
  )
}

export default UserListControls
