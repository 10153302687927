import React, { Fragment } from 'react'
import Popup, { Item as PopupItem } from '../Popup'
import Button from '../Button'

import styles from './UserList.module.scss'

const RowActions = ({ user, deleteUser, showForm, resendInvite }) => {
  return (
    <div className={styles.buttons}>
      <Button className={styles.editButton} secondary size="small" onClick={() => showForm(user.id)}>Edit</Button>
      <Popup
        className={styles.popupWrapper}
        autohide
        content={
          <Fragment>
            <PopupItem onClick={() => resendInvite(user)}>Resend Invite</PopupItem>
            <PopupItem onClick={() => window.confirm('Delete this user?') && deleteUser(user.id)}>Delete User</PopupItem>
          </Fragment>
        }
      >
        <Button icon="ChevronDown" secondary size="small" className={styles.popupButton}/>
      </Popup>
    </div>
  )
}

export default RowActions
