import React, { Fragment, useRef } from 'react'
import { Item as PopupItem } from '../Popup'
import Papa from 'papaparse'

const validate = item => {
  const requiredFields = [
    'FirstName',
    'LastName',
    'FullName',
    'Email',
    'Roles'
  ]
  const valid = requiredFields.reduce((valid, field) => item[field] ? valid : false, true)
  return valid
}

const ImportUsers = ({ loading, importUsers, roles, showMessage }) => {
  const fileInput = useRef()
  const handleChange = e => {
    const file = e.target.files[0]
    if (!file) return
    const reader = new FileReader()
    reader.onload = ee => {
      const csv = ee.target.result
      const { data, errors } = Papa.parse(csv, { header: true, skipEmptyLines: true })
      if (data && errors.length < 1) {
        const items = data.map(item => validate(item) && ({
          givenName: item.FirstName,
          surname: item.LastName,
          displayName: item.FullName,
          mail: item.Email,
          roles: item.Roles && item.Roles.split(',').map(s => roles.reduce((f, r) => r.displayName === s.trim() ? r.id : f, null))
        })).filter(Boolean)
        console.log(items)
        importUsers(items)
      } else {
        showMessage({
          status: 'error',
          title: 'Something went wrong',
          text: 'Check the file and try again'
        })
      }
    }
    reader.readAsBinaryString(file)
  }

  const triggerClick = e => {
    if (!loading) {
      fileInput.current.click()
    }
  }

  return (
    <Fragment>
      <input
        accept=".csv"
        type="file"
        ref={fileInput}
        onChange={handleChange}
        style={{ display: 'none' }} />
      <PopupItem style={{ position: 'relative' }} onClick={triggerClick}>
        Import Users
      </PopupItem>
    </Fragment>
  )
}

export default ImportUsers
