const tenantId = {
  development: '5c057681-cb46-4030-b04d-ea13eb3fe70f',
  production: '6fa997b1-af21-44b7-93a0-5adef6a6ed46'
}

const clientId = {
  development: '3e50aa1f-bcaf-4156-913c-5d4c4591eca8',
  production: '574a5d3c-c40e-472e-b5af-c8d725bed3aa'
}

const functionID = {
  development: '3e50aa1f-bcaf-4156-913c-5d4c4591eca8',
  production: '574a5d3c-c40e-472e-b5af-c8d725bed3aa'
}

const authURL = 'https://login.microsoftonline.com/' +
  getValue(tenantId) +
  '/oauth2/v2.0/authorize' +
  `?client_id=${getValue(clientId)}` +
  `&redirect_uri=${window.location.origin}/authorize` +
  `&scope=api://${getValue(functionID)}/function.app` +
  `&response_mode=fragment` +
  `&grant_type=client_credentials` +
  `&response_type=token`
export default authURL

function getValue(value) {
  return value[process.env.NODE_ENV] || value.development
}
