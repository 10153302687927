import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { VALIDATE_STORED_CREDENTIALS, LOGOUT } from './ducks/auth'

import './styles/global.scss'
import Layout from './containers/Layout'
import PrivateRoute from './containers/PrivateRoute'
import Login from './containers/Login'
import Authorize from './containers/Authorize'
import Users from './containers/Users'

const mapDispatchToProps = dispatch => {
  return {
    validateCredentials: ({ id, token }) => dispatch(VALIDATE_STORED_CREDENTIALS({ id, token })),
    logOut: () => dispatch(LOGOUT())
  }
}

class AppRoutes extends React.Component {
  constructor(props) {
    super(props)
    const currentUser = localStorage.getItem('currentUser')
    if (currentUser) {
      this.props.validateCredentials(JSON.parse(currentUser))
    } else if (!window.location.href.includes('/authorize') && !window.location.href.includes('/login')) {
      this.props.logOut()
    }
  }
  render() {
    return (
      <Layout>
        <Switch>
          <PrivateRoute path="/" exact component={Users} />
          <Route path="/login" component={Login} />
          <Route path="/authorize" component={Authorize} />
        </Switch>
      </Layout>
    )
  }
}

export default connect(null, mapDispatchToProps)(AppRoutes)
