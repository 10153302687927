import keyBy from 'lodash/keyBy'
import download from 'js-file-download'
import { createSlice } from '@reduxjs/toolkit'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import { SHOW_MESSAGE } from './messages'
import { START_POLLING } from './jobs'
import { restAPI } from '../api'

const { actions, reducer } = createSlice({
  initialState: {
    submitting: false,
    deleting: false,
    invalidated: true,
    loading: false,
    lastUpdated: null,
    lastEvaluated: null,
    roleFilter: '',
    editing: '',
    formActive: false,
    byId: {},
    fetchingSingle: false
  },
  name: 'users',
  reducers: {
    SHOW_FORM(state, { payload }) {
      state.editing = payload
      state.formActive = true
    },
    HIDE_FORM(state) {
      state.editing = ''
      state.formActive = false
    },
    CREATE_USER(state) {
      state.submitting = true
    },
    CREATE_USERS(state) {
      state.submitting = true
    },
    UPDATE_USER(state) {
      state.submitting = true
    },
    DELETE_USER(state) {
      state.deleting = true
    },
    REQUEST_USER(state, { payload }) {
      state.fetchingSingle = true
    },
    REQUEST_EXPORT(state, { payload }) {
      state.exporting = true
    },
    REQUEST_EXPORT_SUCCESS(state, { payload }) {
      state.exporting = false
    },
    REQUEST_USERS(state, { payload }) {
      if (!payload.backgroundLoad) {
        state.byId = {}
        state.loading = true
      }
    },
    REQUEST_RESEND_INVITE() {
      // noop
    },
    USER_CREATE_SUCCESS(state, { payload }) {
      state.submitting = false
      state.invalidated = true
      const user = payload.users && payload.users[0]
      if (user) {
        state.byId = { ...state.byId, [user.id]: user }
      }
    },
    USERS_CREATE_SUCCESS(state, { payload }) {
      state.submitting = false
      state.invalidated = true
      const users = payload.users
      if (users) {
        state.byId = { ...state.byId, ...keyBy(payload.users, 'id') }
      }
    },
    USER_UPDATE_SUCCESS(state, { payload }) {
      state.submitting = false
      state.invalidated = true
      state.byId = { ...state.byId, [payload.id]: payload }
    },
    USER_DELETE_SUCCESS(state, { payload }) {
      state.deleting = false
      state.invalidated = true
      delete state.byId[payload]
    },
    USERS_REQUEST_SUCCESS(state, { payload }) {
      state.invalidated = false
      state.submitting = false
      state.fetchingSingle = false
      state.loading = false
      state.lastUpdated = new Date().toString()
      state.byId = { ...state.byId, ...keyBy(payload.users, 'id') }
      state.lastEvaluated = payload.lastEvaluated
    },
    FILTER_USERS(state, { payload }) {
      state.invalidated = true
      state.roleFilter = payload
    },
    INVALIDATE_USERS(state) {
      state.invalidated = true
    },
    USER_REQUEST_FAILED(state) {
      state.loading = false
      state.exporting = false
      state.submitting = false
      state.deleting = false
    }
  }
})

function * onCreateUser({ payload }) {
  try {
    const environment = yield select(state => state.environments.current)
    const { data } = yield call(
      restAPI.post, `/users?environment=${environment}`, {
        users: [ payload ]
      }
    )
    yield put(actions.USER_CREATE_SUCCESS(data))
    yield put(SHOW_MESSAGE({ status: 'success', title: 'User Created' }))
  } catch ({ response }) {
    yield put(actions.USER_REQUEST_FAILED({ message: (response && response.data.message) || 'Something went wrong' }))
  }
}

function * onCreateUsers({ payload }) {
  try {
    const environment = yield select(state => state.environments.current)
    const { data } = yield call(
      restAPI.post, `/users?environment=${environment}`, {
        users: payload
      }
    )
    yield put(SHOW_MESSAGE({ status: 'warning', title: 'Import Started', message: 'This may take a few minutes' }))
    yield put(START_POLLING())
  } catch ({ response }) {
    yield put(actions.USER_REQUEST_FAILED({ message: (response && response.data.message) || 'Something went wrong' }))
  }
}

function * onUpdateUser({ payload }) {
  try {
    const env = yield select(state => state.environments.current)
    const { data } = yield call(
      restAPI.patch, `/users/${payload.id}?environment=${env}`, payload
    )
    yield put(actions.USER_UPDATE_SUCCESS(data))
    yield put(SHOW_MESSAGE({ status: 'success', title: 'User Updated' }))
  } catch ({ response }) {
    yield put(actions.USER_REQUEST_FAILED({ message: (response && response.data.message) || 'Something went wrong' }))
  }
}

function * onDeleteUser({ payload }) {
  try {
    const env = yield select(state => state.environments.current)
    yield call(restAPI.delete, `/users/${payload}?environment=${env}`)
    yield put(actions.USER_DELETE_SUCCESS(payload))
    yield put(SHOW_MESSAGE({ status: 'success', title: 'User Deleted' }))
  } catch ({ response }) {
    yield put(actions.USER_REQUEST_FAILED({ message: (response && response.data.message) || 'Something went wrong' }))
  }
}

function * onRequestExport({ payload }) {
  try {
    const environment = yield select(state => state.environments.current)
    const { data } = yield call(restAPI.get, `/exportcsv`, {
      params: { environment },
      cache: { ignoreCache: true }
    })
    yield put(actions.REQUEST_EXPORT_SUCCESS(data))
    download(data, `users_export_${Date.now()}.csv`)
  } catch ({ response }) {
    yield put(actions.USER_REQUEST_FAILED({ message: (response && response.data.message) || 'Something went wrong' }))
  }
}

function * onRequestUser({ payload }) {
  try {
    const environment = yield select(state => state.environments.current)
    const invalidated = yield select(state => state.users.invalidated)
    const fullUserFetched = yield select(state => state.users.byId[payload] && state.users.byId[payload].roles)
    const ignoreCache = invalidated || !fullUserFetched
    const { data } = yield call(restAPI.get, `/users/${payload}`, {
      params: { environment },
      cache: { ignoreCache }
    })
    yield put(actions.USERS_REQUEST_SUCCESS({ users: [data] }))
  } catch ({ response }) {
    yield put(actions.USER_REQUEST_FAILED({ message: (response && response.data.message) || 'Something went wrong' }))
  }
}

function * onRequestUsers({ payload }) {
  try {
    const env = yield select(state => state.environments.current)
    const ignoreCache = yield select(state => state.users.invalidated)
    const params = { environment: env }
    if (payload.role) {
      params.role = payload.role
    }
    if (env) {
      const { data } = yield call(
        restAPI.get, '/users', {
          params,
          cache: { ignoreCache }
        }
      )
      yield put(actions.USERS_REQUEST_SUCCESS(data))
    }
  } catch ({ response }) {
    yield put(actions.USER_REQUEST_FAILED({ message: (response && response.data.message) || 'Something went wrong' }))
  }
}

function * onRequestResendInvite({ payload }) {
  try {
    const env = yield select(state => state.environments.current)
    yield call(
      restAPI.post, `/users/${payload.id}/reinvite/?environment=${env}`, {
        mail: payload.mail,
        displayName: payload.displayName
      }
    )
    yield put(SHOW_MESSAGE({ status: 'success', title: 'Invitation Sent' }))
  } catch ({ response }) {
    yield put(actions.USER_REQUEST_FAILED({ message: (response && response.data.message) || 'Something went wrong' }))
  }
}

function * onRequestUserFail({ payload }) {
  yield put(SHOW_MESSAGE({ status: 'error', title: payload.title, text: payload.message }))
}

export function * saga() {
  yield takeLatest('users/CREATE_USER', onCreateUser)
  yield takeLatest('users/CREATE_USERS', onCreateUsers)
  yield takeLatest('users/UPDATE_USER', onUpdateUser)
  yield takeLatest('users/DELETE_USER', onDeleteUser)
  yield takeLatest('users/REQUEST_USER', onRequestUser)
  yield takeLatest('users/REQUEST_USERS', onRequestUsers)
  yield takeLatest('users/REQUEST_EXPORT', onRequestExport)
  yield takeLatest('users/REQUEST_RESEND_INVITE', onRequestResendInvite)
  yield takeLatest('users/USER_REQUEST_FAILED', onRequestUserFail)
}

export const { CREATE_USER, CREATE_USERS, UPDATE_USER, DELETE_USER, REQUEST_USER, REQUEST_USERS, REQUEST_RESEND_INVITE, FILTER_USERS, SHOW_FORM, HIDE_FORM, INVALIDATE_USERS, REQUEST_EXPORT } = actions

export default reducer
