import { connect } from 'react-redux'
import { UPDATE_USER, CREATE_USER, REQUEST_USER, HIDE_FORM } from '../ducks/users'
import UserForm from '../components/UserForm'

const mapStateToProps = state => {
  return {
    userId: state.users.editing,
    user: state.users.editing && state.users.byId[state.users.editing],
    rolesById: state.roles.byId,
    submitting: state.users.submitting,
    loading: state.users.fetchingSingle
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchUser: id => dispatch(REQUEST_USER(id)),
    hide: () => dispatch(HIDE_FORM()),
    handleUpdate: data => dispatch(UPDATE_USER(data)),
    handleCreate: data => dispatch(CREATE_USER(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserForm)
