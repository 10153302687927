import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import Logo from '../../assets/Logo'
import LogOut from '../../assets/LogOut'
import EnvPicker from '../../containers/EnvironmentSelect'
import { FiUsers } from 'react-icons/fi'
import styles from './Nav.module.scss'

const Nav = ({ logout }) => (
  <nav className={styles.root}>
    <div className={styles.wrapper}>
      <Link to="/">
        <Logo className={styles.logo} />
      </Link>
      <div className={styles.links}>
        <div className={styles.link} onClick={logout}>
          <LogOut /> <span>Log out</span>
        </div>
      </div>
      <div className={styles.envPicker}>
        <EnvPicker />
      </div>
    </div>
  </nav>
)

export default Nav
