import React, { useEffect } from 'react'
import styles from './UserForm.module.scss'
import { FiUser } from 'react-icons/fi'
import Form, { Field } from '../Form'
import Input from '../Input'
import Select from '../Select'
import Button from '../Button'

const UserForm = ({ user, userId, handleUpdate, handleCreate, fetchUser, loading, submitting, hide, rolesById }) => {
  const allRoles = Object.values(rolesById).map(role => ({ text: role.displayName, value: role.id }))
  const alphaNumericWithSpaces = [{ rule: 'matches', options: [/^[A-Z\s]+$/i] }]

  const onSubmit = ({ values, valid }) => {
    if (!valid) return
    if (user) {
      const data = { ...values, id: userId }
      handleUpdate(data)
    } else {
      handleCreate(values)
    }
    hide()
  }

  useEffect(() => {
    if (userId) {
      fetchUser(userId)
    }
  }, [userId, fetchUser])

  if (loading) {
    return ''
  }

  return (
    <Form onSubmit={onSubmit} className={styles.root}>
      <h2 className={`${styles.full} ${styles.heading}`}>
        <FiUser className={styles.headerIcon} />
        {user ? 'Edit User' : 'Create New User'}
      </h2>
      <Field label="First Name" required className={styles.full}>
        <Input
          type="text"
          placeholder="e.g. Jane"
          name="givenName"
          defaultValue={user ? user.givenName : ''} />
      </Field>
      <Field label="Last Name(s)" required className={styles.full}>
        <Input
          type="text"
          placeholder="e.g. Smith"
          name="surname"
          defaultValue={user ? user.surname : ''} />
      </Field>
      <Field label="Display Name" required className={styles.full}>
        <Input
          type="text"
          placeholder="e.g. Jane Smith"
          name="displayName"
          defaultValue={user ? user.displayName : ''} />
      </Field>
      <Field label="Email" validations={['isEmail']} className={styles.full}>
        <Input
          disabled={!!user}
          type="email"
          placeholder="e.g. jane.smith@council.co.uk"
          name="mail"
          defaultValue={user ? user.mail : ''} />
      </Field>
      <Field
        label="Roles"
        required
        className={styles.full}>
        <Select
          name="roles"
          options={allRoles}
          multiple
          defaultValue={user && user.roles && user.roles.length > 0
            ? user.roles.map(role => ({ value: role, text: rolesById[role] ? rolesById[role].displayName : role }))
            : null
          }
        />
      </Field>
      <Button type="button" disabled={submitting} className={styles.button} secondary onClick={hide}>
        Cancel
      </Button>
      <Button type="submit" disabled={submitting} className={styles.button} icon="Check">
        {user
          ? 'Update'
          : 'Create'}
      </Button>
    </Form>
  )
}

export default UserForm
