import { connect } from 'react-redux'
import { FILTER_USERS, SHOW_FORM, REQUEST_EXPORT } from '../ducks/users'
import { REQUEST_ROLES } from '../ducks/roles'
import UserListControls from '../components/UserListControls'

const mapStateToProps = state => {
  return {
    roleFilter: state.users.roleFilter,
    roles: Object.values(state.roles.byId),
    loading: state.users.loading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateRoleFilter: role => dispatch(FILTER_USERS(role)),
    showForm: () => dispatch(SHOW_FORM()),
    requestExport: () => dispatch(REQUEST_EXPORT()),
    fetchRoles: () => dispatch(REQUEST_ROLES())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserListControls)
