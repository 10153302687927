import React from 'react'

const Windows = props => (
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 475.082 475.082" fill="currentColor" height="1em" width="1em" {...props}>
    <polygon points="0,409.705 194.716,436.541 194.716,250.677 0,250.677" />
    <polygon points="0,226.692 194.716,226.692 194.716,38.544 0,65.38" />
    <polygon points="216.129,439.398 475.082,475.082 475.082,250.677 475.082,250.674 216.129,250.674" />
    <polygon points="216.132,35.688 216.132,226.692 475.082,226.692 475.082,0" />
  </svg>
)

export default Windows
