import { createSlice } from '@reduxjs/toolkit'
import { call, put, takeLatest, select, delay } from 'redux-saga/effects'
import { SHOW_MESSAGE } from './messages'
import { REQUEST_USERS } from './users'
import { restAPI } from '../api'

const POLL_INTERVAL = 12000

const { actions, reducer } = createSlice({
  initialState: {
    polling: false,
    activeJobs: [],
    lastUpdated: 0
  },
  name: 'jobs',
  reducers: {
    REQUEST_JOBS(state) {
      state.loading = true
    },
    START_POLLING(state) {
      state.polling = true
    },
    REQUEST_JOBS_SUCCESS(state, { payload }) {
      state.lastUpdated = new Date().toString()
      state.activeJobs = payload
      state.polling = payload.length > 0
    },
    JOBS_REQUEST_FAILED(state) {
      state.polling = true
      state.error = true
    }
  }
})

function * onRequestJobs() {
  try {
    const environment = yield select(state => state.environments.current)
    const polling = yield select(state => state.jobs.polling)
    const { data } = yield call(
      restAPI.get, `/jobs?environment=${environment}`, { cache: {
        ignoreCache: true
      } }
    )
    if (data.jobs.length < 1 && polling) {
      yield put(REQUEST_USERS({}))
      yield put(SHOW_MESSAGE({ status: 'success', title: 'Import Successful' }))
    }
    yield put(actions.REQUEST_JOBS_SUCCESS(data.jobs))
    if (data.jobs.length > 0) {
      yield delay(POLL_INTERVAL)
      yield put(actions.REQUEST_JOBS())
    }
  } catch ({ response }) {
    yield put(actions.JOBS_REQUEST_FAILED({ message: (response && response.data.message) || 'Something went wrong' }))
  }
}

function * onStartPolling() {
  yield delay(POLL_INTERVAL)
  yield put(actions.REQUEST_JOBS())
}

export function * saga() {
  yield takeLatest('jobs/REQUEST_JOBS', onRequestJobs)
  yield takeLatest('jobs/START_POLLING', onStartPolling)
}

export const { REQUEST_JOBS, START_POLLING } = actions

export default reducer
