import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { SAVE_CURRENT_URL } from '../ducks/auth'
import { REQUEST_ENVIRONMENTS } from '../ducks/environments'
import { connect } from 'react-redux'

const mapStateToProps = state => {
  return {
    loggedIn: !!state.auth.currentUser,
    loggingIn: !!state.auth.loggingIn,
    envsLoading: !!state.environments.loading,
    validating: !!state.auth.validating
  }
}

const mapDispatchToProps = dispatch => {
  return {
    saveLocation: () => dispatch(SAVE_CURRENT_URL(window.location.pathname)),
    fetchEnvs: () => dispatch(REQUEST_ENVIRONMENTS())
  }
}

const PrivateRoute = ({ validating, loggedIn, loggingIn, location, saveLocation, envsLoading, fetchEnvs, ...otherProps }) => {
  useEffect(() => {
    if (loggedIn && !validating) {
      fetchEnvs()
    }
  }, [ loggedIn, fetchEnvs, validating ])

  if (validating || envsLoading) {
    return ''
  }
  if (!loggedIn) {
    saveLocation()
  }

  return loggedIn
    ? <Route {...otherProps} />
    : <Redirect to={{
      pathname: '/login',
      state: { from: location }
    }} />
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
