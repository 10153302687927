import React from 'react'
import Select from '../Select'

const EnvPicker = ({ environments, changeEnvironment, currentEnv }) => {
  const options = environments.map(env => ({
    text: `${env.displayName} environment`,
    value: env.id
  }))

  const handleChange = e => {
    if (currentEnv !== e.target.value) {
      changeEnvironment(e.target.value)
    }
  }
  return currentEnv ? (
    <Select options={options} defaultValue={options.reduce((t, c) => c.value === currentEnv ? c : t, options[0])} onChange={handleChange} />
  ) : ''
}

export default EnvPicker
