import { createSlice } from '@reduxjs/toolkit'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import { SHOW_MESSAGE } from './messages'
import { restAPI as API, updateToken, authURL } from '../api'

const { actions, reducer } = createSlice({
  name: 'auth',
  initialState: {
    currentUser: false,
    validating: true,
    loggingIn: false,
    roles: [],
    byId: {}
  },
  reducers: {
    LOGIN_ATTEMPT(state, { payload }) {
      state.loggingIn = true
    },
    LOGIN_SUCCESS(state, { payload }) {
      const token = parseJwt(payload.token)
      state.currentUser = token.oid
      state.roles = token.roles
      state.loggingIn = false
      state.byId[token.oid] = token
      state.validating = false
    },
    LOGIN_FAILED(state, { payload }) {
      state.currentUser = null
      state.loggingIn = false
      state.auth = null
      state.validating = false
    },
    LOGOUT(state, { payload }) {
      state.validating = false
      state.currentUser = null
    },
    VALIDATE_STORED_CREDENTIALS(state, { payload }) {
      state.loggingIn = true
      state.validating = true
    },
    LOGIN_RESTORED(state, { payload }) {
      if (payload && payload.token) {
        const token = parseJwt(payload.token)
        state.currentUser = token.oid
        state.byId[token.oid] = payload
        state.roles = token.roles
        state.loggingIn = false
        state.validating = false
      }
    },
    SAVE_CURRENT_URL(state, { payload }) {
      state.redirectTo = payload
    }
  }
})

// we lose our context when trying to call localStorage.X
// inside these functions if we don't do this
const setItem = window.localStorage.setItem.bind(localStorage)
const removeItem = window.localStorage.removeItem.bind(localStorage)

function * onLoginAttempt({ payload }) {
  try {
    const redirectTo = yield select(state => state.auth.redirectTo)
    window.location.href = authURL +
      (payload && payload.prompt ? `&prompt=${payload.prompt}` : '') +
      (payload && payload.email ? `&login_hint=${payload.email}` : '') +
      (redirectTo ? `&state=${redirectTo}` : '')
  } catch (err) {
    yield put(actions.LOGIN_FAILED({ message: err.message }))
  }
}

function * onLoginSuccess({ payload }) {
  yield call(updateToken, payload.token)
  yield put(SHOW_MESSAGE({ status: 'success', title: 'Logged in' }))
  yield call(setItem, 'currentUser', JSON.stringify(payload))
}

function * onLoginFailed({ payload }) {
  if (!payload || !payload.silent) {
    yield put(SHOW_MESSAGE({ status: 'error', title: 'Login Failed', text: 'Check your email/password and try again' }))
  }
}

function * onLogout({ payload }) {
  yield call(removeItem, 'currentUser')
  window.location.href = '/login'
}

function * onValidateCredentials({ payload }) {
  yield call(updateToken, payload.token)
  try {
    yield call(API.get, '/validate')
    yield put(actions.LOGIN_RESTORED(payload))
  } catch (err) {
    yield call(removeItem, 'currentUser')
    const email = yield select(state => state.auth.byId[state.auth.currentUser] && state.auth.byId[state.auth.currentUser].preferred_username)
    yield put(actions.LOGIN_ATTEMPT({
      prompt: 'none',
      email
    }))
  }
}

export function * saga() {
  yield takeLatest('auth/LOGIN_ATTEMPT', onLoginAttempt)
  yield takeLatest('auth/LOGIN_SUCCESS', onLoginSuccess)
  yield takeLatest('auth/LOGIN_FAILED', onLoginFailed)
  yield takeLatest('auth/VALIDATE_STORED_CREDENTIALS', onValidateCredentials)
  yield takeLatest('auth/LOGOUT', onLogout)
}

export const { LOGIN_ATTEMPT, LOGIN_SUCCESS, LOGIN_FAILED, LOGOUT, LOGIN_RESTORED, SAVE_CURRENT_URL, VALIDATE_STORED_CREDENTIALS } = actions

export default reducer

function parseJwt(token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}
