import keyBy from 'lodash/keyBy'
import { createSlice } from '@reduxjs/toolkit'
import { call, put, takeLatest } from 'redux-saga/effects'
import { SHOW_MESSAGE } from './messages'
import { restAPI } from '../api'

const { actions, reducer } = createSlice({
  initialState: {
    loading: true,
    lastUpdated: null,
    current: '',
    byId: {}
  },
  name: 'environments',
  reducers: {
    REQUEST_ENVIRONMENTS(state) {
      state.loading = true
    },
    CHANGE_ENVIRONMENT(state, { payload }) {
      if (state.byId[payload]) {
        state.current = payload
      }
    },
    ENVIRONMENTS_REQUEST_SUCCESS(state, { payload }) {
      state.loading = false
      state.lastUpdated = new Date().toString()
      state.byId = { ...state.byId, ...keyBy(payload.environments, 'id') }
      if (!state.current) {
        state.current = payload.environments[0] && payload.environments.reverse()[0].id
      }
    },
    ENVIRONMENT_REQUEST_FAILED(state) {
      state.loading = false
    }
  }
})

function * onRequestEnvironments() {
  try {
    const { data } = yield call(
      restAPI.get, '/environments'
    )
    yield put(actions.ENVIRONMENTS_REQUEST_SUCCESS(data))
  } catch ({ response }) {
    yield put(actions.ENVIRONMENT_REQUEST_FAILED({ message: (response && response.data.message) || 'Something went wrong' }))
  }
}
function * onRequestEnvironmentFail({ payload }) {
  yield put(SHOW_MESSAGE({ status: 'error', title: payload.title, text: payload.message }))
}

export function * saga() {
  yield takeLatest('environments/REQUEST_ENVIRONMENTS', onRequestEnvironments)
  yield takeLatest('environments/ENVIRONMENT_REQUEST_FAILED', onRequestEnvironmentFail)
}

export const { REQUEST_ENVIRONMENTS, CHANGE_ENVIRONMENT } = actions

export default reducer
